import React, { ReactElement } from 'react';
import './FormMandatSepaFooter.scss';

export default function FormMandatSepaFooter(): ReactElement {
  return (
    <div className="formMandatSepaFooter">
      <p className="formMandatSepaFooter__title">Protection des données personnelles</p>
      <p className="formMandatSepaFooter__text">
        Les informations recueillies dans le présent formulaire, ou contenues dans les pièces demandées, ont pour
        finalités la gestion et l’exécution du contrat, la gestion du risque et, si accord de votre part, la prospection
        commerciale. Vous disposez des droits d’accès, de rectification, d’opposition, de portabilité, d’effacement et
        de limitation du traitement vous concernant. Pour les exercer, adressez un courrier simple au responsable de
        traitement : Tutélaire, 157 avenue de France, 75013 Paris ou un mail à :{' '}
        <a href="mailto:donneespersonnelles@tutelaire.fr">donneespersonnelles@tutelaire.fr</a>. Pour plus d’informations
        relatives à la gestion de vos données, consultez le règlement du contrat sâge autonomie. Vous pouvez également
        consulter à tout moment, notre politique de confidentialité sur le site internet{' '}
        <a href="http://www.tutelaire.fr/" target="_blank" rel="noreferrer">
          www.tutelaire.fr
        </a>{' '}
        ou la demander par courrier simple au responsable de traitement de Tutélaire.
      </p>
      <p className="formMandatSepaFooter__coord">
        Tutélaire, mutuelle soumise aux dispositions du livre II du code de la mutualité
        <br />
        SIREN 775 682 164 – Siège social 157 avenue de France 75013 Paris
      </p>
    </div>
  );
}
