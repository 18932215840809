import { yupResolver } from '@hookform/resolvers/yup';
import React, { ReactElement } from 'react';
import FormConsent from '../../components/FormConsent/FormConsent.component';
import FormNextButton from '../../components/FormNextButton/FormNextButton.component';
import {
  ClientResiliationTutelaireProps,
  DocumentResiliationTutelaireProps,
  useSaveClientResiliationTutelaire,
  useSaveDocumentResiliationTutelaire
} from '../../hooks/useResiliationTutelaire.hook';
import { useSerieForm } from '../../hooks/useSerieForm.hook';
import { FormProps } from '../../types';
import parseSearch from '../../utils/parseSearch';
import Yup from '../../utils/Yup';
import './FormResiliationConfirmation.scss';
import { LabelByMotifResiliation, MotifResiliation } from './data/MotifResiliation';
import { LabelByTypeContrat } from './data/TypeContrat';
import FormResiliationFooter from './FormResiliationFooter.component';

type FormValues = {
  consentement: boolean;
  consentementBis: boolean;
};

type PreviousFormValues = {
  documentsToUpload: DocumentResiliationTutelaireProps[];
} & ClientResiliationTutelaireProps;

const defaultState: FormValues = {
  consentement: false,
  consentementBis: false
};
const validationSchema: Yup.SchemaOf<FormValues> = Yup.object({
  consentement: Yup.boolean()
    .required()
    .isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.'),
  consentementBis: Yup.boolean()
    .required()
    .isTrue('Vous devez obligatoirement cocher cette case pour valider votre demande.')
}).defined();

export default function FormResiliationConfirmation(props: FormProps<FormValues>): ReactElement {
  const [loading, setLoading] = React.useState(false);

  const id = parseSearch(window.location.search, 'id');
  if (!id) throw new Error("Le formulaire n'a pas été trouvé.");

  const { documentsToUpload, ...clientData }: PreviousFormValues = props.previousFormValues;
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useSerieForm<FormValues>(props.setLastFormState, {
    resolver: yupResolver(validationSchema),
    defaultValues: defaultState // always reset to default state
  });
  const handleNextClick = async (data: FormValues) => {
    setLoading(true);
    const numeroClient = await useSaveClientResiliationTutelaire({ ...clientData, idFormulaire: id });
    for (const document of documentsToUpload) {
      await useSaveDocumentResiliationTutelaire({ ...document, numeroClient: numeroClient });
    }
    setLoading(false);
    props.goNextStep(data);
  };

  const getMotifResiliationValue = (key: MotifResiliation) => {
    if (key === MotifResiliation.AUTRE) {
      return clientData.motifResiliationAutre;
    } else {
      return LabelByMotifResiliation[key];
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNextClick)} className="formResiliationConfirmation">
      <p className="title">Récapitulatif et confirmation de la demande de résiliation</p>
      <p className="intro">
        <p>
          <b>Nom</b>: {clientData.nom}
        </p>
        <p>
          <b>Prénom</b>: {clientData.prenom}
        </p>
        <p>
          <b>Téléphone</b>: {clientData.telephone}
        </p>
        <p>
          <b>Email</b>: {clientData.email}
        </p>
        <p>
          <b>Contrat concerné</b> : {LabelByTypeContrat[clientData.typeContrat]}
        </p>
        <p>
          <b>Numéro d&apos;adhérent</b>: {clientData.numeroAdherent}
        </p>
        <p>
          <b>Motif de résiliation</b>: {getMotifResiliationValue(clientData.motifResiliation)}
        </p>
        <p>
          <b>Date de résiliation souhaitée</b> : {clientData.dateResiliationSouhaite.toLocaleDateString()}
        </p>
        <br />
        Une confirmation vous sera adressée par email précisant les effets de la résiliation et la date à<br />
        laquelle le contrat prend fin, sous réserve du respect des conditions légales et contractuelles.
        <br />
        <br />
        Important : les garanties de votre contrat resteront en vigueur jusqu&apos;à la date effective de votre
        <br />
        résiliation.
      </p>
      <br />
      <FormConsent
        className="consentement"
        contenuConsentement={
          'Afin d’accéder à votre demande, nous sommes dans l’obligation de stocker et de traiter vos données personnelles. Si vous souhaitez poursuivre votre demande, veuillez cocher la case ci-dessous afin de nous autoriser le stockage et traitement de vos données à cette fin uniquement. '
        }
        control={control}
        errors={errors}
        label={
          'J’accepte que mes données personnelles soient stockées et traitées afin d’obtenir une réponse à ma requête.'
        }
        name="consentement"
      />
      <FormConsent
        contenuConsentement={' '}
        control={control}
        errors={errors}
        label={'Je certifie que les renseignements donnés ci-dessus sont exacts.'}
        name="consentementBis"
      />
      <FormNextButton showCaptcha={props.showCaptcha} setCaptchaToken={props.setCaptchaToken} loading={loading}>
        Envoyer ma demande de résiliation
      </FormNextButton>
      <FormResiliationFooter />
      <br />
      <p className="formResiliationConfirmationFooterCoord">
        <p>
          Tutélaire, mutuelle soumise aux dispositions du livre II du code de la mutualité
          <br />
          SIREN 775 682 164 – Siège social 157 avenue de France 75013 Paris
        </p>
        <p className="link">
          <a href="https://www.tutelaire.fr/mentions-legales-cookies" target="_blank" rel="noreferrer">
            Mentions légales
          </a>
          -
          <a href="https://www.tutelaire.fr/protection-des-donnees" target="_blank" rel="noreferrer">
            Protection des données
          </a>
        </p>
      </p>
    </form>
  );
}
