import React, { ReactElement } from 'react';
import './FormResiliationFooter.scss';

const FormResiliationFooter: () => ReactElement = () => (
  <div className="formResiliationFooter">
    <p className="formResiliationFooter__title">Protection des données personnelles</p>
    <p className="formResiliationFooter__text">
      Les informations recueillies dans le présent formulaire, ou contenues dans les pièces demandées, ont pour finalité
      le traitement
      <br />
      de votre demande de résiliation. Vous disposez notamment des droits d&apos;accès, de rectification,
      d&apos;opposition, de portabilité,
      <br />
      d&apos;effacement et de limitation du traitement vous concernant. Pour l&apos;exercice de vos droits, adressez un
      courrier simple au
      <br />
      responsable de traitement : Tutélaire, 157 avenue de France, 75013 Paris ou un courriel à :
      donneespersonnelles@tutelaire.fr. Pour
      <br />
      plus d&apos;informations relatives à la gestion de vos données, vous pouvez à tout moment consulter notre
      politique de confidentialité
      <br />
      sur le site internet www.tutelaire.fr ou la demander par courrier simple au responsable de traitement de
      Tutélaire.
    </p>
  </div>
);

export default FormResiliationFooter;
