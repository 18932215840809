import InputCheckBoxBoolean from 'components/Input/InputCheckBox/InputCheckBoxBoolean.component';
import InputSelect from 'components/Input/InputSelect/InputSelect.component';
import InputText from 'components/Input/InputText/InputText.component';
import React, { VFC } from 'react';
import { Control, FieldErrors, UseFormRegister } from 'react-hook-form';
import InputDatePicker from '../../components/Input/InputDatePicker/InputDatePicker.component';
import { ChampPersonnalise } from '../../types';
import InputNumber from '../../components/Input/InputNumber/InputNumber.component';
import _ from 'lodash';
import './FieldGenerator.scss';

interface FieldGeneratorProps {
  champPersonnaliseAttr: any;
  tooltip?: any;
  fields: ChampPersonnalise[] | null;
  errors: FieldErrors<any>;
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: any;
}

const FieldGenerator: VFC<FieldGeneratorProps> = (props: FieldGeneratorProps) => {
  const renderField = (field: any, champPersonnaliseAttr: any) => {
    if (field.valeuresRestreintes) field.type = 'SELECT';
    const name = `${champPersonnaliseAttr}[--${field.id}].valeur`;
    switch (field.type) {
      case 'NUMBER': {
        return (
          <div>
            <InputNumber
              tooltip={field.description}
              min={0}
              label={field.libelle}
              {...props.register(name)}
              errors={props.errors}
            />
          </div>
        );
      }
      case 'SELECT': {
        const selectOptions = field.valeuresRestreintes.reduce((mapOpt: any, item: any) => {
          mapOpt.push({
            label: item.valeur,
            value: item.id
          });
          return mapOpt;
        }, []);
        return (
          <div>
            <InputSelect
              tooltip={field.description}
              label={field.libelle}
              control={props.control}
              errors={props.errors}
              name={name}
              options={selectOptions}
            />
          </div>
        );
      }
      case 'BOOLEAN': {
        return (
          <div>
            <InputCheckBoxBoolean
              tooltip={field.description}
              control={props.control}
              label={field.libelle}
              name={name}
              errors={props.errors}
            />
          </div>
        );
      }
      case 'STRING': {
        return (
          <div>
            <InputText
              tooltip={field.description}
              label={field.libelle}
              {...props.register(name)}
              errors={props.errors}
            />
          </div>
        );
      }
      case 'DATE': {
        return (
          <div>
            <InputDatePicker
              tooltip={field.description}
              label={field.libelle}
              name={name}
              errors={props.errors}
              control={props.control}
            />
          </div>
        );
      }
      default:
        return (
          <div>
            <InputText
              tooltip={field.description}
              label={field.libelle}
              {...props.register(name)}
              errors={props.errors}
            />
          </div>
        );
    }
  };

  const fieldsByRegroupement = _.groupBy(props.fields, item => item.regroupement || 'Non défini');
  return (
    <div>
      {props.fields &&
        Object.keys(fieldsByRegroupement)
          .sort()
          .map(regroupement => (
            <>
              {regroupement !== 'Non défini' && (
                <div className="regroupement" key={`regroupement-fields-${regroupement}`}>
                  <h3 className="title">{regroupement}</h3>
                  {fieldsByRegroupement[regroupement].map((field: any) =>
                    renderField(field, props.champPersonnaliseAttr)
                  )}
                </div>
              )}
              {regroupement === 'Non défini' &&
                fieldsByRegroupement[regroupement].map((field: any) => renderField(field, props.champPersonnaliseAttr))}
            </>
          ))}
    </div>
  );
};

export default FieldGenerator;
